<template>
  <div
    class="py-10 lg:py-24"
    :class="{
      'px-4': theme === 'light',
      'md:px-4': theme === 'dark',
    }"
  >
    <div
      class="flex flex-col md:flex-row w-full max-w-wrapper-md m-auto items-center"
      :class="[
        {
          'bg-blue-900 px-4 py-6 md:p-10 md:pb-12 md:rounded-[1.25rem] text-white gap-12': theme === 'dark',
          'gap-8': theme === 'light'
        },
        innerWrapperClass
      ]"
    >
      <div class="flex-1">
        <atoms-headline
          tag="p"
          class="lg:text-3xl text-2xl leading-9 text-inherit pl-4 lg:pl-3"
          :class="[headlineClass]"
        >
          <slot name="header"></slot>
        </atoms-headline>

        <p class="text-inherit text-base font-normal mt-4">
          <slot name="sub-header"></slot>
        </p>

        <!--
          Since we have to define emits in nuxt3, and since this button is an array, I added handling for `callback` key, to replace the emit functionality before.

          example:
          :buttons="[
            {
              label: 'label',
              callback: () => () // function here
            }
          ]"

          this is will only work, if there is no `to` key in the button obj which redirects it before triggering the callback function
        -->
        <div
          v-if="buttons"
          class="flex flex-col md:flex-row gap-4 mt-6 flex-wrap"
        >
          <atoms-button
            v-for="(button, buttonKey) in buttons"
            :id="button?.id ?? `button_${generateUID()}`"
            :key="buttonKey"
            :cross-app="button.crossApp ?? false"
            v-bind="buttonAttrs(button, 'main')"
            v-on="{
              ...((button?.callback && {
                click: () => button.callback?.()
              }) || {})
            }"
          >
            {{ button.label }}
          </atoms-button>
        </div>

        <div v-if="$slots['buttom-disclaimer']">
          <slot name="buttom-disclaimer"></slot>
        </div>

        <div v-if="$slots['bottom-content']" class="hidden lg:block">
          <slot name="bottom-content"></slot>
        </div>
      </div>

      <slot
        v-if="!(hideRightContentOnMobile && !devices.isDesktop.value)"
        name="right-content"
      >
        <atoms-card
          v-if="layout !== 'minimal'"
          size="md"
          class="flex flex-col items-center text-center w-full md:max-w-[293px] lg:max-w-[352px] pt-6 pb-8 px-4"
        >
          <img
            :src="expertIcon"
            alt="customer care"
            class="m-auto pb-6"
            :class="[
              homeTalkToUsImgDetails && homeTalkToUsImgDetails.classList,
              {
                'order-2': cardTitlePosition === 'top'
              }
            ]"
            v-bind="homeTalkToUsImgDetails.dimensions"
            :loading="imageLazyLoad ? 'lazy' : 'eager'"
          />

          <p
            class="mb-2"
            :class="{
              'text-2xl': !$slots['cta-card-title'],
              'text-xl': $slots['cta-card-title'],
              'order-1': cardTitlePosition === 'top'
            }"
          >
            <slot name="cta-card-title">
              <strong>Want to speak to us?</strong>
            </slot>
          </p>

          <p
            class="text-base font-normal order-3"
          >
            <slot name="cta-card-description">
              Our <strong class="font-bold">experts</strong> are here to help.
            </slot>
          </p>

          <atoms-button
            v-if="!isEmpty(cardCtaConfig) || cardCtaConfig?.show !== false"
            v-bind="buttonAttrs(cardCtaConfig, 'secondary')"
            class="order-4 mt-4"
          >
            {{ cardCtaConfig.label }}
          </atoms-button>
        </atoms-card>
      </slot>
    </div>
    <div v-if="$slots['bottom-content']" class="lg:hidden">
      <slot name="bottom-content"></slot>
    </div>
  </div>
</template>

<script setup>
import _pick from 'underscore/cjs/pick.js'
import _isEmpty from 'underscore/cjs/isEmpty.js'

import { objectKeysValidator } from '~/helpers/validator'

const { devices } = deviceChecker()

defineOptions({
  name: 'HomeTalkToUs'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validator: value => ['light', 'dark'].includes(value)
  },

  layout: {
    type: String,
    default: 'default',
    validator: value => ['default', 'minimal'].includes(value)
  },

  innerWrapperClass: {
    type: String,
    default: ''
  },

  headlineClass: {
    type: String,
    default: ''
  },

  expertIcon: {
    type: String,
    default: `${APP_ASSET_BASEURL}edge/static/icons/customer-care-blue.svg`
  },

  expertIconDimensions: {
    type: Object,
    default: () => {
      return {
        width: 140,
        height: 141
      }
    },
    validator: obj => objectKeysValidator(obj, ['height', 'width'])
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  buttons: {
    type: Array,
    default: () => [
      {
        label: 'COMPARE & SAVE',
        to: '/',
        type: 'button',
        theme: 'primary',
        size: 'large',
        hasIcon: false
      }
    ]
  },

  cardCtaConfig: {
    type: Object,
    default: () => ({
      label: VERTICALS.Default.phoneNumber,
      type: 'button',
      theme: 'secondary',
      full: false,
      size: 'medium',
      class: 'mt-4',
      hasIcon: false
    })
  },

  cardTitlePosition: {
    type: String,
    default: 'default',
    validator: value => ['default', 'top'].includes(value)
  },

  hideRightContentOnMobile: {
    type: Boolean,
    default: false
  }
})

const homeTalkToUsImgDetails = computed(() => {
  const icon = props.expertIcon
  let dimensions = props.expertIconDimensions
  let classList = ''

  if (icon.includes('customer-care-green.svg')) {
    dimensions = {
      width: 115,
      height: 105
    }
    classList = 'w-[7.1875rem] h-[6.5625rem]'
  } else if (icon.includes('customer-care-blue.svg')) {
    dimensions = {
      width: 140,
      height: 141
    }
    classList = 'w-[8.75rem] h-[8.8125rem]'
  }

  return {
    dimensions,
    classList
  }
})

const buttonPropList = ['class', 'show', 'label', 'type', 'theme', 'size', 'hasIcon', 'full', 'enableTracking', 'to']

const isEmpty = val => (_isEmpty(val))

const buttonAttrs = (config, type) => {
  const to = (() => {
    const label = config?.label
    const phone = config?.phone
    const uri = config?.to

    if (phone) {
      return `tel:${phone}`
    }

    if (!phone && !isNaN(label && label.replace(/\s/g, ''))) {
      return `tel:${label}`
    }

    return uri
  })()

  const tempConfig = {
    hasIcon: false,
    size: type === 'main' ? 'large' : 'medium',
    theme: type === 'main' ? 'primary' : 'secondary',
    type: 'button',
    ...config,
    to
  }

  const newProps = Object.keys(tempConfig)
    .filter(key => buttonPropList.includes(key))

  return _pick(tempConfig, newProps)
}
</script>
